/*
 * Copyright 2020, Jean Traullé <jtraulle@opencomp.fr>
 *
 * @copyright Copyright 2020, Jean Traullé <jtraulle@opencomp.fr>
 *
 */

import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import "jquery/dist/jquery";
import "@popperjs/core/dist/esm/popper";
import { Tooltip, Popover } from "bootstrap/dist/js/bootstrap.esm";

import "select2";
import "select2/dist/js/i18n/fr";
import ClipboardJS from "clipboard";
import "./BeforeXhrHook";
import "lity/dist/lity";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/fontawesome";
import MetisMenu from "metismenujs";
import PerfectScrollbar from "perfect-scrollbar";
import Swal from "sweetalert2/dist/sweetalert2";
import shortAndSweet from "short-and-sweet/dist/short-and-sweet.module";

import "@fortawesome/fontawesome-free/css/svg-with-js.css";
import "sweetalert2/src/sweetalert2.scss";

window.Swal = Swal;
FontAwesome.config.autoAddCss = false;

const appContainer = $(".app-container");

if (document.querySelector(".vertical-nav-menu") !== null) {
    const menu = new MetisMenu(".vertical-nav-menu");
    menu.update();
}

const scrollbarContainers = document.getElementsByClassName("scrollbar-container");
Array.prototype.forEach.call(scrollbarContainers, function (el) {
    const ps = new PerfectScrollbar(el, {
        wheelSpeed: 2,
        wheelPropagation: false,
        minScrollbarLength: 20
    });
    ps.update();
});

const scrollbarSidebar = document.getElementById("scrollbar-sidebar");
if (scrollbarSidebar !== null) {
    const psSidebar = new PerfectScrollbar(scrollbarSidebar, {
        wheelSpeed: 2,
        wheelPropagation: false,
        minScrollbarLength: 20
    });
    psSidebar.update();
}

shortAndSweet("textarea", {
    counterClassName: "remaining-chars"
});

$(".mobile-toggle-nav").on("click touch", function () {
    $(this).toggleClass("is-active");
    appContainer.toggleClass("sidebar-mobile-open");
});

$(".mobile-toggle-header-nav").on("click touch", function () {
    $(this).toggleClass("active");
    $(".app-header__content").toggleClass("header-mobile-open");
});

// Responsive
var resizeClass = function () {
    var win = document.body.clientWidth;
    if (win <= 1366) {
        appContainer.addClass("closed-sidebar");
    } else {
        appContainer.removeClass("closed-sidebar");
    }
};


$(window).on("resize", function () {
    resizeClass();
});

resizeClass();

$(".close-sidebar-btn").click(function () {
    var classToSwitch = $(this).attr("data-class");
    var containerElement = ".app-container";
    $(containerElement).toggleClass(classToSwitch);

    var closeBtn = $(this);

    if (closeBtn.hasClass("is-active")) {
        closeBtn.removeClass("is-active");
    } else {
        closeBtn.addClass("is-active");
    }
});

const jdenticon = require("jdenticon");
jdenticon.configure({
    lightness: {
        color: [0.40, 0.80],
        grayscale: [0.30, 0.90]
    },
    saturation: {
        color: 0.74,
        grayscale: 0.00
    },
    backColor: "#ffffffff"
});
jdenticon();

const debug = $("meta[name=debug]").attr("content");

if (debug === "0") {
    Sentry.init({
        dsn: "https://4476251db8d942348e8bec2be8e2419b@glitchtip.opencomp.fr/1",
        autoSessionTracking: false,
        release: $("#version").text(),
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: "production"
    });
}

// On récupère la date courante = la date de dernière réinitialisation de la session.
window.dateLastSessionTimeoutReset = new Date();

// Forcer le rafraîchissement de la page (pb de cache Chrome pour les tokens CSFR)
async function handleHardReload() {
    const url = window.location.href;
    if (url.startsWith("https://my.opencomp.fr/") || url.startsWith("https://my.opencomp.local/")) {
        await fetch(url, {
            headers: {
                Pragma: "no-cache",
                Expires: "-1",
                "Cache-Control": "no-cache",
            },
        });

        window.location.href = url;
    }
    window.location.reload();
}

// Cette fonction permet de vérifier que la session n'est pas expirée.
const checkSessionTimeout = function () {
    const minutes = Math.abs((window.dateLastSessionTimeoutReset - new Date()) / 1000 / 60);
    if (minutes > 20) {
        // La session est expirée, on arrête l'appel à la fonction de vérification
        // et on affiche la modale pour forcer l'utilisateur à se reconnecter.
        window.clearInterval(window.checkSessionId);
        Swal.fire({
            title: "🔑 Votre session a expiré",
            text: "En raison d'une trop longue période d'inactivité, votre session a expiré et vous devez vous reconnecter.",
            confirmButtonText: "Se reconnecter",
            allowEscapeKey: false,
            allowEnterKey: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleHardReload();
            }
        });
    }
};

// On exécute la fonction de vérification d'expiration de la session toutes les 5 secondes.
window.checkSessionId = setInterval(checkSessionTimeout, 5000);

//Bootstrap Javascript components initialization
var tooltipTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"tooltip\"]"));
tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl, { trigger: "hover", container: "body", boundary: "window" });
});
var popoverTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"popover\"]"));
popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl, { trigger: "hover", container: "body", boundary: "window" });
});

$(".myBreadcrumb > *:first-child").attr("title", "");

$("#reportError").click(function () {
    if ($("#eventId").text() === "") {
        $crisp.push(["do", "chat:open"]);
    } else {
        Sentry.init({ dsn: "https://82a0c973e30c409bb338beaedc0492bd@sentry.io/170388" });
        Sentry.showReportDialog({
            lang: "fr",
            title: "Il semblerait que nous ayons un problème ...",
            subtitle2: "Aidez-nous en ajoutant des détails. Merci 😘",
            eventId: $("#eventId").text(),
            user: {
                name: $("#reporterName").text(),
                email: $("#reporterEmail").text()
            }
        });
    }
});

//Select2 init
$("select:visible:not(.noselect2)").select2({
    minimumResultsForSearch: 10,
    theme: "bootstrap",
});

//Enable clipboard copy
const clipboard = new ClipboardJS(".clipboard");
clipboard.on("success", function (e) {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
    });

    Toast.fire({
        icon: "success",
        title: "La clé d'API est dans le presse-papier."
    });

    e.clearSelection();
});

//Envoyer automatiquement le focus au premier champ visible de la page
$("form").find("input[type=text],textarea,select").filter(":visible:first").focus().select();
$(".focus").focus();

//Handle flash messages with Sweet Alert
const typeFlashMessage = $("input[name=typeFlashMessage]").val();
if (typeFlashMessage !== undefined) {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
    });
    const message = JSON.parse($("input[name=flashMessage]").val());

    switch (typeFlashMessage) {
    case "info":
        Toast.fire({
            icon: "info",
            title: message
        });
        break;
    case "warning":
        Toast.fire({
            icon: "warning",
            title: message
        });
        break;
    case "success":
        Toast.fire({
            icon: "success",
            title: message
        });
        break;
    case "error":
        Toast.fire({
            icon: "error",
            title: message
        });
        break;
    }
}

// Handle confirm message with Sweet Alert
var confirmTriggerList = [].slice.call(document.querySelectorAll("[data-confirm-message]"));
confirmTriggerList.map(function (confirmTriggerEl) {
    confirmTriggerEl.onclick = function (){
        const confirmMessage = confirmTriggerEl.dataset.confirmMessage;
        const formName = confirmTriggerEl.dataset.formName;
        const form = document.getElementsByName(formName)[0];
        Swal.fire({
            html: confirmMessage,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d92550",
            confirmButtonText: "Oui, continuer !",
            cancelButtonText: "Annuler",
        }).then((result) => {
            if (result.value) {
                form.submit();
            }
        });
    };
});
